<template>
  <v-container fluid class="px-6" >

    <v-card>
      <v-card-title class="px-6 card-header white--text">
        <v-icon class="mr-3 white--text">mdi-format-list-bulleted</v-icon> <h3>User List</h3>
      </v-card-title>

      <div style="max-height: calc(100vh - 190px);overflow-y:auto;">
        <v-data-table
        :headers="headers"
        :items="itemsWithIndex"
        class="elevation-2 headerDtSarawak px-6 pt-6"
        >
          <template v-slot:[`item.no`]="props">
            {{ props.item.index }}
          </template>

          <template v-slot:[`item.fullname`]="props">
            {{ props.item.firstname }} {{ props.item.lastname }}
          </template>

          <template v-slot:[`item.actions`]="props">
            <template>
              <v-icon
                small
                class="mr-2 green--text"
                @click="editUserItem(props.item)"
                >mdi-pencil</v-icon
              >
              <v-icon
                small
                text
                class="red--text"
                @click="deleteUser(props.item.idUser,props.item.name)"
                >mdi-delete</v-icon
              >
            </template>

          </template>
        </v-data-table>
      </div>
    </v-card>

    <!------ Dialog Edit User ------>
    <v-dialog v-model="editDialog" max-width="700px" persistent>
      <v-card>
        <v-card-title class="card-header white--text">
          <v-icon class="white--text mr-2">mdi-account-edit</v-icon><h3>Edit User</h3>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedUser.firstname"
                  label="First Name"
                  dense outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedUser.lastname"
                  label="Last Name"
                  dense outlined 
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedUser.email"
                  label="Email"
                  dense outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedUser.designation"
                  label="Designation"
                  dense outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="editedUser.idUserAccess"
                  :items="roles"
                  label="Role(s)"
                  dense outlined
                  item-value="idUserAccess"
                  item-text="userAccessName"
                  @change="selectRoles(editedUser.idUserAccess)"
                ></v-select>
              </v-col>

              <v-col cols="12" md="12" class="pb-0 pt-0">

                <v-checkbox
                  class="mr-4"
                  v-model="editedUser.alert1"
                  label="Alert"
                  false-value="0"
                  true-value="1"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="card-footer">
          <v-spacer></v-spacer>

          <!--<v-btn class="primary--text" text @click="close">
            Cancel
          </v-btn>

          <v-btn class="primary--text" text @click="postEditUser">
            Save
          </v-btn>-->

          <v-btn
            @click="editDialog=false"
            color="error"
          >
            CANCEL
          </v-btn>
          <v-btn
            @click="postEditUser"
            color="button" class="white--text px-4 mx-3" :loading="loadingButton"
          >
            SAVE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!------ Dialog Delete User ------>
    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="error white--text">
          <v-icon class="white--text mr-2">mdi-delete-empty</v-icon><h3>Delete User : {{delUserName}}</h3>  
        </v-card-title>
        <!--<v-card-text style="padding:20px 24px;font-weight:bold;">
          Are you sure you want to delete this user? ({{delUserName}})
        </v-card-text>-->

        <v-card-text class="pa-5 d-flex justify-center">
          <h3>
            Are you sure you want to delete this user? 
          </h3>
        </v-card-text>

        <v-card-actions class="card-footer">
          <v-spacer></v-spacer>
          <v-btn class="error" @click="close">CANCEL</v-btn>
          <v-btn color="button" class="white--text px-4 mx-3" @click="postDeleteUser">DELETE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!----- Snackbar Message ---->
    <v-snackbar
    v-model="snackbarMsg"
    :color="snackbarColor"
    >
        {{ message }}

        <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbarMsg = false"
        >
            <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
        </template>
    </v-snackbar>

    
  </v-container>
</template>


<script>
import axios from "axios";
import appMixins from '@/mixins/globalMixins'

export default {

  data: () => ({
    snackbarMsg: false,
    snackbarColor: '',
    message: '',
    loadingButton: false,
    editDialog: false,
    dialogDelete: false,
    delSelected: "",
    delUserName: "",

    headers: [
      { text: "NO.", align: "start", value: "no" },
      { text: "NAME", value: "fullname" },
      { text: "EMAIL", value: "email" },
      { text: "DESIGNATION", value: "designation" },
      { text: "ROLE(s)", value: "userAccessFname" },
      { text: "ACTIONS", value: "actions", sortable: false },
    ],
    userData: [],
    roles: [],
    editedUser: {
      IdUser: "",
      idUserAccess: null,
      dataValidationStatus: "",
      firstname: "",
      lastname: "",
      email: "",
      designation: "",
      phone: "",
      role: null,
      alert1: "",
    },
    editedIndex: -1,
  }),

  methods: {
    getAllUser() {
      axios
        .get(this.globalUrl+'users/all', {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.userData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getRoles(){
      axios
      .get(this.globalUrl+'users/alldropdowns', {
        headers: {
          Authorization: "Bearer " + this.$store.getters.user.token,
        },
      })
      .then((response) => {
        console.log(response.data);
        this.roles = response.data.userAccess;
      })
      .catch((error) => {
        console.log(error);
      });
    },

    postDeleteUser() {
      axios
        .get(this.globalUrl+'users/del?id='+this.delSelected,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          this.close();
          this.getAllUser();
          this.getRoles();

          this.snackbarMsg = true;
          this.snackbarColor = "success";
          this.message = "User has been deleted";
        })
        .catch((error) => {
          console.log(error);
          this.close();
          this.snackbarMsg = true;
          this.snackbarColor = "error";
          this.message = "Error. Please try again";
        });
    },

    editUserItem(item) {

      console.log(item);

      // this.editedIndex = this.userData.indexOf(item);
      // this.editedUser = Object.assign({}, item);

      this.editedUser = item;
      this.editDialog = true;

      console.log(this.editedUser);
    },

    selectRoles(roleId){
      this.editedUser.role = roleId;
    },

    postEditUser() {
      this.loadingButton = true;
      let payload = this.editedUser;

      console.log("aaaaaaaaaaaaaaaa",payload);
      
      axios
        .post(this.globalUrl+'users/edit', payload, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.getAllUser();
          this.loadingButton = false;
          this.close();
        })
        .catch((error) => {
          this.loadingButton = false;
          console.log(error);
        });
    },

    deleteUser(val,user) {
      this.delSelected = val;
      this.delUserName = user;
      this.dialogDelete = true;
    },

    close() {
      this.editDialog = false;
      this.dialogDelete = false;
    },
  },

  computed: {
    itemsWithIndex() {
      return this.userData.map((userData, index) => ({
        ...userData,
        index: index + 1,
      }));
    },
  },

  mounted() {
    this.getAllUser();
    this.getRoles();
  },
};
</script>

<style lang="scss" scoped>
@import "~scss/main";



    
</style>